define("ember-cookie-consent/services/cookie-consent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    displayConsent: function displayConsent() {
      var bgColor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "#000";
      var popupTextColor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "#FFF";
      var btnColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "#000";
      var btnTextColor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "#000";
      var position = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "top";
      var msg = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "Þessi vefsíða notar vafrakökur.";
      var dismissMsg = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : "Samþykkja";
      var readMoreText = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : "Sjá nánar";
      var domain = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : ".stokkur.is";
      var isStatic = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : false;
      var url = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : "javascript:void(0)";

      window.cookieconsent.initialise({
        "palette": {
          "popup": {
            "background": bgColor,
            "text": popupTextColor

          },
          "button": {
            "background": btnColor,
            "text": btnTextColor
          }
        },
        "position": position,
        "static": isStatic,
        "elements": {
          'messagelink': '<span id="cookieconsent:desc" class="cc-message">\{\{message\}\} <a aria-label="learn more about cookies" tabindex="0" class="cc-link" id="cc-link" href=\{\{href\}\}>\{\{link\}\}</a></span>',
          'link': '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" id="cc-link" href=\{\{href\}\}>\{\{link\}\}</a>'
        },
        "content": {
          "message": msg,
          "dismiss": dismissMsg,
          "link": readMoreText,
          "href": url
        },
        'cookie.domain': domain
      });
    }
  });
});