define('ember-cookie-consent/components/cookie-consent', ['exports', 'ember-cookie-consent/templates/components/cookie-consent'], function (exports, _cookieConsent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _cookieConsent.default,
    cookieConsent: Ember.inject.service(),
    elementId: 'cc-addon',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var delay = this.get('delay') || 0;
      var that = this;
      Ember.run.later(this, function () {
        this.get('cookieConsent').displayConsent(this.get('bgColor'), this.get('popupTextColor'), this.get('btnColor'), this.get('btnTextColor'), this.get('position'), this.get('msg'), this.get('dismissMsg'), this.get('readMoreText'), this.get('cookieDomain'), this.get('isStatic'));
        $(".cc-compliance").click(function () {
          that.set('showModal');
        });

        $("#cc-link").click(function () {
          that.toggleProperty('showModal');
          if (that.get('showModal')) {
            $('#cc-addon').css('opacity', 1);
          } else {
            $('#cc-addon').css('opacity', 0);
          }
        });
      }, delay);
    }
  });
});